@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import "./assets/styles/_variables.scss";
@import "./assets/styles/_elements.scss";
@import "./assets/styles/_media-quaries.scss";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Roboto:wght@400;500;700&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif !important;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.container {
  padding: 0;
  max-width: 1314px;
  margin: 0 auto;
  width: 100%;
}

.breadcrumb-container {
  max-width: 1314px;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  width: 100vw !important;
}

.ant-drawer-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ant-drawer-header-no-title .ant-drawer-close {
  font-size: 22px;
}

.ant-breadcrumb-link {
  font-size: 14px !important;
  line-height: 22px !important;
}

.ant-menu-sub.ant-menu-inline {
  background: white !important;
}

.ant-menu-submenu-title:hover {
  color: rgba(0, 148, 152, 1) !important;
}

.ant-menu-submenu-title:active {
  background: #f2fcef !important;
}
.ant-table-thead > tr > th {
  background: white !important;
  text-align: right !important;
  color: $primary !important;
}
.ant-table-tbody > tr > td {
  font-size: 14px;
  line-height: 22px;
  @include below(xs) {
    padding: 12px;
    white-space: pre-wrap;
    overflow-wrap: inherit !important;
    font-size: 12px;
    line-height: 20px;
    max-width: 69px;
  }
}
.ant-table-thead > tr > th {
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  @include below(xs) {
    max-width: 69px;
    padding: 12px;
    white-space: nowrap;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    padding: 8px !important;
  }
}
.ant-table-tbody > tr > td:not(.ant-table-tbody > tr > td:first-child) {
  text-align: right !important;
}
.ant-table-tbody > tr > td {
  color: $black !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background: $primary !important;
  border-color: $primary !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  &:hover {
    background: #00b9be !important;
    border-color: #00b9be !important;
  }

  &:active,
  &:focus {
    background: $primary-700 !important;
    border-color: $primary-700 !important;
  }
}

.ant-spin-dot-item {
  background-color: $primary !important;
}

.ant-table-content {
  overflow: auto;
}

.notification-text {
  font-weight: 700;
  font-size: 24px;
  letter-spacing: $letter-spacing;
  padding: 22px 32px !important;
}

.anticon.ant-notification-notice-icon-info {
  color: $primary !important;
}

.speciality-wrapper .ant-menu-sub.ant-menu-inline {
  max-height: 150px;
  overflow: scroll;
  overflow-x: hidden;
}
