$primary: #009498;
$primary-700: #006f72;
$primary-200: #ccf1f2;
$secondary-200: #eaf9e6;
$secondary: #f2fcef;
$black: #000000;
$gray: #474747;
$green: #389e0d;
$white: #ffffff;
$red: #d4380d;
$black-85: rgba(45, 45, 45, 0.85);

$border-color: #d9d9d9;

$letter-spacing: 0.025em;
$box-shadow: 0px 2px 0px;
